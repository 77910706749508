import React, {useState, useEffect} from "react";
import {BrowserRouter as Router, Routes, Route, useParams} from "react-router-dom";
import TokenRewards from "./routes/token-rewards/token-rewards";
import HomePage from "./routes/homepage/homepage";
import Test from "./routes/test/test";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Test />} />
                <Route path="/:network/:tokenName" element={<TokenRewards />} />
                <Route path="/test" element={<HomePage />} />
            </Routes>
        </Router>
    );
}


export default App;
