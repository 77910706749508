import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import './test.css'
import {Card, Col, Divider, InputNumber, Row, Select, Slider, Space} from 'antd';
import {PercentageOutlined} from "@ant-design/icons";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function Test() {

    const {network, tokenName} = {
        network: 'mainnet', tokenName: 'osmo'
    }

    const [value, setValue] = useState(0);
    const [maxValue, setMaxValue] = useState(1000000);

    const [numberData, setNumberData] = useState({
        apr: 0, commission: 0, tokenName: "NaN", price: 0,
    });
    const [calculation, setCalculation] = useState({});


    useEffect(() => {
        // Fetch data from API
        fetch(`https://configs.nyc3.digitaloceanspaces.com/validator-website/networks/rewards-calculator/${tokenName}-rewards.json`)
            .then((response) => response.json())
            .then((data) => setNumberData(data))
            .catch((error) => console.log(error));
    }, [tokenName, network]);

    useEffect(() => {
        // Calculate values based on slider value and number data
        const {apr, commission, tokenName, price, sliderMaxValue} = numberData;
        setCalculation({
            yearlyRewardsInTokens: numberWithCommas((value * apr).toFixed(2)),
            monthlyRewardsInTokens: numberWithCommas((((value * apr) / 12).toFixed(2))),
            dailyRewardsInTokens: numberWithCommas(((value * apr) / 365).toFixed(2)),

            yearlyRewardsInDollars: numberWithCommas((value * apr * price).toFixed(2)),
            monthlyRewardsInDollars: numberWithCommas((((value * apr * price) / 12).toFixed(2))),
            dailyRewardsInDollars: numberWithCommas(((value * apr * price) / 365).toFixed(2)),
            sliderMaxValue: sliderMaxValue,
            apr: apr,
            valueToBeStake: numberWithCommas(( value * price).toFixed(2))
        });

        setMaxValue(sliderMaxValue);

    }, [value, numberData]);

    const handleSliderChange = (value) => {
        setValue(value);
    };


    return (

        <Space direction="vertical" size="small"
               style={{
                   width: 'calc(100% - 160px)',
                   margin: '80px',
                   border: '1px solid #ccc',
                   borderRadius: '10px',
                   overflow: 'auto'
               }}>
            <Row>
                <Col span={12}
                     style={{
                         padding: '10px',
                     }}
                >
                    <Select
                        size={"large"}
                        style={{width: '100%'}}
                        showSearch
                        placeholder="Select a Chain"
                        optionFilterProp="children"
                        // onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={[{
                            value: 'OSMO', label: 'Osmosis',
                        }]}
                    />
                    <h1 style={{
                        fontSize: 'calc(48px + 1.4vw)',
                        fontFamily: 'Arial Black, sans-serif',
                        color: '#444444',
                        WebkitTextStroke: '2px #FFFEFC',
                        textShadow: '2px 2px 0px rgba(0, 0, 0, 1)',
                        marginBottom: '0px'
                    }}>Osmosis</h1>
                    <p style={{
                        fontSize: '18px', fontFamily: 'Georgia',
                    }}>How much OSMO do you want to stake?</p>
                    <Divider style={{
                        marginBottom: '60px'
                    }}></Divider>

                    <Slider
                        min={0}
                        max={maxValue}
                        onChange={handleSliderChange}
                        trackStyle={{
                            backgroundColor: 'rgb(221, 237, 204)', height: '10px'
                        }}
                        railStyle={{height: '10px'}}
                        style={{
                            marginBottom: '40px',

                        }}
                        handleStyle={{
                            marginTop: '2.5px', backgroundColor: 'transparent',
                        }}
                        value={typeof value === 'number' ? value : 0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />

                    <Row>
                        <Col span={16}>
                            <InputNumber
                                size={"large"}
                                addonAfter="OSMO"
                                min={0}
                                style={{
                                    width: '100%', paddingRight: '10px'
                                }}
                                value={value}
                                onChange={handleSliderChange}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>

                        <Col span={8}>
                            <InputNumber
                                readOnly={true}
                                size={"large"}
                                addonBefore="$"
                                min={0}
                                style={{
                                    width: '100%', // fontSize: '20px'
                                }}
                                value={calculation.valueToBeStake}
                                // onChange={onChangeSlider}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>
                    </Row>

                </Col>
                <Col span={12}
                     style={{
                         borderLeft: '1px solid #cccccc'
                     }}>
                    <Card
                        hoverable={true}
                        size="large"
                        title="APY"
                        style={{
                            borderRadius: '0px', border: '0.5px solid #ccc', backgroundColor: 'rgb(221, 237, 204, 0.2)',
                        }}
                        headStyle={{
                            fontWeight: 'normal', marginBottom: '0px'
                        }}
                        bodyStyle={{
                            fontFamily: 'monospace', paddingTop: '10px'
                        }}
                    >
                        <p style={{
                            fontSize: '28px', margin: '0px'
                        }}
                        >{calculation.apr * 100} %</p>
                    </Card>
                    <Card
                        hoverable={true}
                        size="large"
                        title="Monthly Rewards"
                        style={{
                            width: '100%',
                            borderRadius: '0px',
                            backgroundColor: 'rgb(250, 235, 221, 0.2)',
                            border: "0.5px solid #ccc"
                        }}
                        headStyle={{
                            fontWeight: 'normal', marginBottom: '0px'
                        }}
                        bodyStyle={{
                            fontFamily: 'monospace', paddingTop: '10px'
                        }}
                    >
                        <p style={{
                            fontSize: '28px', margin: '0px'
                        }}
                        >{calculation.monthlyRewardsInTokens} {numberData.tokenName}</p>
                        <p style={{
                            fontSize: '18px', margin: '0px', color: '#888888'
                        }}
                        >$ {calculation.monthlyRewardsInDollars}</p>
                    </Card>
                    <Row>
                        <Col span={12}>
                            <Card
                                hoverable={true}
                                size="large"
                                title="Daily Rewards"

                                style={{
                                    width: '100%',
                                    borderRadius: '0px',
                                    backgroundColor: 'rgb(251, 243, 219, 0.2)',
                                    border: "0.5px solid #ccc"
                                }}
                                headStyle={{
                                    fontWeight: 'normal', marginBottom: '0px'
                                }}
                                bodyStyle={{
                                    fontFamily: 'monospace', paddingTop: '10px'
                                }}
                            >
                                <p style={{
                                    fontSize: '28px', margin: '0px'
                                }}
                                >{calculation.dailyRewardsInTokens} {numberData.tokenName}</p>
                                <p style={{
                                    fontSize: '18px', margin: '0px', color: '#888888'
                                }}
                                >$ {calculation.dailyRewardsInDollars}</p>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                hoverable={true}
                                size="large"
                                title="Yearly Rewards"
                                style={{
                                    width: '100%',
                                    borderRadius: '0px',
                                    backgroundColor: 'rgb(244, 223, 235, 0.2)',
                                    border: "0.5px solid #ccc"
                                }}
                                headStyle={{
                                    fontWeight: 'normal', marginBottom: '0px'
                                }}
                                bodyStyle={{
                                    fontFamily: 'monospace', paddingTop: '10px'
                                }}
                            >
                                <p style={{
                                    fontSize: '28px', margin: '0px'
                                }}
                                >{calculation.yearlyRewardsInTokens} {numberData.tokenName}</p>
                                <p style={{
                                    fontSize: '18px', margin: '0px', color: '#888888'
                                }}
                                >$ {calculation.yearlyRewardsInDollars}</p>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Space>);
}

export default Test;
