import React, {useState, useEffect} from "react";
import "./token-rewards.css";
import "../../../src/App.css"
import {useParams} from "react-router-dom";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removeCommas(x) {
    return x.toString().replace(/,/g, "");
}

function TokenRewards() {

    const {network, tokenName} = useParams();

    const [value, setValue] = useState(0);
    const [maxValue, setMaxValue] = useState(1000000);

    const [numberData, setNumberData] = useState({
        apr: 0, commission: 0, tokenName: "NaN", price: 0,
    });
    const [calculation, setCalculation] = useState({});




    useEffect(() => {
        // Fetch data from API
        fetch(`https://configs.nyc3.digitaloceanspaces.com/validator-website/networks/rewards-calculator/${tokenName}-rewards.json`)
            .then((response) => response.json())
            .then((data) => setNumberData(data))
            .catch((error) => console.log(error));
    }, [tokenName, network]);

    useEffect(() => {
        // Calculate values based on slider value and number data
        const {apr, commission, tokenName, price, sliderMaxValue} = numberData;
        setCalculation({
            yearlyRewardsInTokens: numberWithCommas((value * apr).toFixed(2)),
            monthlyRewardsInTokens: numberWithCommas((((value * apr) / 12).toFixed(2))),
            dailyRewardsInTokens: numberWithCommas(((value * apr) / 365).toFixed(2)),

            yearlyRewardsInDollars: numberWithCommas((value * apr * price).toFixed(2)),
            monthlyRewardsInDollars: numberWithCommas((((value * apr * price) / 12).toFixed(2))),
            dailyRewardsInDollars: numberWithCommas(((value * apr * price) / 365).toFixed(2)),
            sliderMaxValue: sliderMaxValue,
        });

        setMaxValue(sliderMaxValue);

    }, [value, numberData]);

    const handleSliderChange = (event) => {
        setValue(Number(event.target.value));
    };

    const handleValueChange = (event) => {
        setValue(Number(event.target.value));

    };

    return (<div className="container">
        <div className="header">
        </div>
        <div className="title-container box-shadow">
            <center>
                <h2>Estimate your {numberData.tokenName} staking rewards</h2>
            </center>
        </div>
        <div className="slider-container box-shadow">
            How much {numberData.tokenName} do you want to stake?
            <div className="slider">
                <input
                    type="range"
                    min="0"
                    max={maxValue}
                    value={value}
                    onChange={handleSliderChange}
                />
                <input
                    type="number"
                    min="0"
                    max={maxValue}
                    value={value}
                    onChange={handleValueChange}
                />
            </div>
        </div>
        <div className="calculation-container">
            <table>
                <tbody>
                <tr>
                    <td>Daily Rewards</td>
                    <td>
                        {calculation.dailyRewardsInTokens} {numberData.tokenName}
                    </td>
                    <td>$ {calculation.dailyRewardsInDollars}</td>
                </tr>
                <tr>
                    <td>Monthly Rewards</td>
                    <td>
                        {calculation.monthlyRewardsInTokens} {numberData.tokenName}
                    </td>
                    <td>$ {calculation.monthlyRewardsInDollars}</td>
                </tr>
                <tr>
                    <td>Yearly Rewards</td>
                    <td>
                        {calculation.yearlyRewardsInTokens} {numberData.tokenName}
                    </td>
                    <td>$ {calculation.yearlyRewardsInDollars}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>);
}

export default TokenRewards;
