import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "../../../src/App.css";
import "./homepage.css";

function HomePage() {
    const navigate = useNavigate();

    const handleDropdownChange = (event) => {
        const {value} = event.target;
        navigate(`/${value}`);
    };

    return (
        <div className="container">
            <div className="title-container box-shadow">
                <center>
                    <h2>Select a token to estimate staking rewards</h2>
                </center>
            </div>
            <div className="dropdown-container box-shadow">
                <select className="dropdown box-shadow" onChange={handleDropdownChange}>
                    <option value="/">Select</option>
                    <option value="mainnet/osmo">Osmosis</option>
                </select>
            </div>
        </div>
    );
}

export default HomePage;
